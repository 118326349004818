import _drop from 'lodash-es/drop';
import _get from 'lodash-es/get';
import _includes from 'lodash-es/includes';
import _take from 'lodash-es/take';
import { ObserveVisibility } from 'vue-observe-visibility';
import { isInViewport } from '@/node_modules/@osp/utils/src/is-in-viewport';
import { MediaLoading } from '@/node_modules/@osp/design-system/types/media';
import { ImageOptimizationSettings } from '@/node_modules/@osp/design-system/components/Media/Media.props';
import {
	IRenderSizingMixin,
	RenderSizingMixin,
} from '@/node_modules/@osp/design-system/components/mixins/render-sizing-mixin';
import {
	ClsOptimizationMixin,
	IClsOptimizationMixin,
} from '@/node_modules/@osp/design-system/components/mixins/cls-optimization-mixin';
import {
	importRunTask,
	importTimeout,
} from '@/node_modules/@osp/design-system/assets/js/utilities/dynamicImports';
import { Component, Mixins, Prop } from '~/app-utils/decorators';
import { AbstractComponent } from '~/components/mixins/abstract-component';
import { eecPromotionView } from '~/tracking/events/eec.promotionView';
import {
	PRODUCT_TEASER_NAME,
	TEASER_EXPIRING_SHORTAGE_NAME,
	TEXT_NAME,
	TEXT_PICTURE_TEASER_NAME,
} from '~/@constants/global';
import { useCmsContentStore } from '~/@api/store/cmsContentApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';
import { useServerSettingsStore } from '~/@api/store/serverSettingsApi';
import {
	CmsAbstractTeaserData,
	CmsDereferencedEntry,
	CmsDevices,
	CmsGridLayoutComponent,
	CmsGridLayoutRowLayout,
	CmsTeaserExpiringShortageData,
	CmsTeaserTheme,
} from '~/@api/store.types';

@Component({
	components: {
		[TEXT_NAME]: () => import('~/components/molecules/content/text-data.vue'),
		[PRODUCT_TEASER_NAME]: () =>
			import('~/components/molecules/content/teaser/product-teaser/product-teaser.vue'),
		[TEXT_PICTURE_TEASER_NAME]: () =>
			import('~/components/molecules/content/teaser/text-picture/text-picture'),
	},
	directives: { ObserveVisibility },
	mixins: [ClsOptimizationMixin, RenderSizingMixin],
})
export default class GridLayout extends Mixins<
	AbstractComponent & IClsOptimizationMixin & IRenderSizingMixin
>(AbstractComponent) {
	private content: CmsGridLayoutComponent = null;
	private alreadyTrackedPromotions = [];

	@Prop({ required: true })
	public id: string;

	@Prop({ default: 0 })
	public imagePreloadCount: number;

	@Prop({ default: false })
	public preloadLink: boolean;

	@Prop({ default: false })
	public componentPreviewImageOptimization: boolean;

	@Prop({ default: undefined })
	public componentPreviewImageSettings: ImageOptimizationSettings;

	created() {
		importRunTask().then(({ runTask }) => {
			runTask(() => {
				this.content = useCmsContentStore(this.$store).api.element(
					this.id,
				) as CmsGridLayoutComponent;
			});
		});
	}

	mounted() {
		this.clsShowRefElement('self', true);
	}

	/**
	 * Calculate the visible data elements per row
	 */
	get layout() {
		let filteredElements = this.filteredElements;
		let nonTextIndex = 0;

		return this.content?.rows?.map((row) => {
			let data;
			let layout;
			let theme;

			if (row.layout === CmsGridLayoutRowLayout.PERCENT_100) {
				data = this.split(filteredElements, 1);
				layout = [this.$style.grid, this.$style.grid100];
				theme = CmsTeaserTheme.LARGE;
			} else if (row.layout === CmsGridLayoutRowLayout.PERCENT_50) {
				data = this.split(filteredElements, 2);
				layout = [this.$style.grid, this.$style.grid50];
				theme = CmsTeaserTheme.MEDIUM;
			} else if (row.layout === CmsGridLayoutRowLayout.PERCENT_33) {
				data = this.split(filteredElements, 3);
				layout = [this.$style.grid, this.$style.grid33];
				theme = CmsTeaserTheme.SMALL;
			} else if (row.layout === CmsGridLayoutRowLayout.PERCENT_25) {
				data = this.split(filteredElements, 4);
				layout = [this.$style.grid, this.$style.grid25];
				theme = CmsTeaserTheme.TINY;
			}

			filteredElements = data.drop;

			const rowData = {
				nonTextIndex,
				layout,
				references: data.take,
				theme,
			};

			nonTextIndex +=
				(rowData.references?.content as CmsGridLayoutComponent)?.datas?.filter(
					(data) => data.key.split('__')[0] !== TEXT_NAME,
				).length || 0;

			return rowData;
		});
	}

	get filteredElements(): CmsDereferencedEntry[] {
		const cmsContentApi = useCmsContentStore(this.$store).api;

		return (
			this.content?.datas
				?.map((data) => cmsContentApi.dereference(data))
				.filter(this.deviceFilter)
				.filter(this.expireShortageFilter) ?? []
		);
	}

	private split(array: any[], length: number): object {
		return {
			drop: _drop(array, length),
			take: _take(array, length),
		};
	}

	private deviceFilter(element: CmsDereferencedEntry) {
		return _includes(
			(element?.content as CmsAbstractTeaserData).devices,
			useServerContextStore(this.$store).state.userAgent.deviceCategory === 'desktop'
				? CmsDevices.DESKTOP
				: CmsDevices.MOBILE,
		);
	}

	private expireShortageFilter(element: CmsDereferencedEntry) {
		const cmsContentApi = useCmsContentStore(this.$store).api;
		const time: number = Date.now();

		return (
			_get(element?.content, 'shortages', [])
				.map((data) => cmsContentApi.dereference(data))
				.filter((entry: CmsDereferencedEntry) => entry?.component === TEASER_EXPIRING_SHORTAGE_NAME)
				.filter((entry: CmsDereferencedEntry) => {
					const content = entry.content as CmsTeaserExpiringShortageData;

					return content.startTime > time || content.endTime < time;
				}).length === 0
		);
	}

	private shouldGeneratePreloadLink(nonTextIndex: number, refIndex: number): boolean {
		return this.preloadLink && nonTextIndex + refIndex < this.imagePreloadCount;
	}

	rowVisibilityChanged(isVisible: boolean, entry: IntersectionObserverEntry, rowIndex: number) {
		// Id this element visible in viewport
		if (isVisible) {
			if (!this.alreadyTrackedPromotions.includes(entry.target)) {
				// Get teasers for current row (entry.target is the row)
				const teaserTrackingParams = ((this.$refs[`teaser-row-${rowIndex}`] as Vue[]) || []).map(
					(vm: any) => vm.promotionTrackingParams,
				);

				// Do not track empty rows
				if (teaserTrackingParams.length > 0) {
					importTimeout().then(({ setSafeTimeout }) => {
						setSafeTimeout(
							() => {
								if (
									// Check if it is still in the viewport
									isInViewport(entry.target as HTMLElement) &&
									// and not already tracking within the timeout
									!this.alreadyTrackedPromotions.includes(entry.target)
								) {
									this.alreadyTrackedPromotions.push(entry.target);
									eecPromotionView(teaserTrackingParams);
								}
							},
							useServerSettingsStore(this.$store).state.settings.tracking
								.impressionViewIntersectionTimeout || 0,
						);
					});
				}
			}
		}
	}

	getMediaLoading(nonTextIndex: number, refIndex: number): string {
		if (this.shouldGeneratePreloadLink(nonTextIndex, refIndex)) {
			return MediaLoading.EAGER;
		}

		return nonTextIndex + refIndex < this.imagePreloadCount
			? MediaLoading.EAGER
			: MediaLoading.LAZY;
	}
}
